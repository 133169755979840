import React from "react";
import ReactDOM from "react-dom";
import App from "./containers/App";
import "./styles.css";

const rootElement = document.getElementById("root");
let app = null;
const MAINTENANCE_MODE = true;

if (MAINTENANCE_MODE === false) {
  app = <App />;
} else {
  app = <div>Maintennace Mode</div>;
}
ReactDOM.render(app, rootElement);
