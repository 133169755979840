import React from "react";
import PropTypes from "prop-types";
import "./ImagePreview.css";
import LabelsDisplay from "../LabelsDisplay/LabelsDisplay";
import Loader from "./loader.gif";
//vision.init({ auth: "AIzaSyAyyc8zf7HZPLv2XwkQcid_8gkP0rXG_Jc" });

export const ImagePreview = ({ dataUri, isFullscreen, labels }) => {
  let classNameFullscreen = isFullscreen ? "demo-image-preview-fullscreen" : "";

  return (
    <div>
      <div className={"demo-image-preview " + classNameFullscreen}>
        <img src={dataUri} alt="" />
      </div>
      <div className="loader">
        <img src={Loader} alt="" />
      </div>
      <LabelsDisplay labels={labels} />
    </div>
  );
};

ImagePreview.propTypes = {
  dataUri: PropTypes.string,
  isFullscreen: PropTypes.bool
};

export default ImagePreview;
