import React from "react";
import Loader from "./loader.gif";
import GreenTick from "./greentick.gif";
import Sound from "react-sound";
import VuugleSound from "./vuugle-sound.mp3";
const LabelsDisplay = (labels) => {
  console.log("I am in Labels");
  console.log(labels["labels"]);
  var labelLists = ""; /*(
    <img
      src={Loader}
      alt=""
      width="150"
      height="100"
    />
  ); */
  if (labels["labels"][0] !== undefined) {
    //console.log("Mapped Data");
    //console.log(labels["labels"][0]);
    labelLists = labels["labels"][0].map((data) => {
      console.log("Inside Map");
      console.log(data.description);
      var url = null;
      var not_detected = null;
      /*if (data.description === "Eyewear") {
        url = "http://160.153.235.68/~adseen/product/ray-ban-sunglasses/";
      } else if (data.description === "Car") {
        url = "https://www.astonmartin.com/";
      }
      */

      /*  if (data.description === "Eyewear" || data.description === "Car" || data.description === "Man" 
          || data.description === "White Shirt" || data.description === "Sunglasses" || data.description === "Plane"
          || data.description === "Building" || data.description === "Helicopter" || data.description === "Boat"
          || data.description === "People" || data.description === "Suit" || data.description === "Watch"
          || data.description === "Road" || data.description === "Sea" || data.description === "Mobile Phone"
          || data.description === "Tablet" || data.description === "Hand" || data.description === "Tree"
          || data.description === "Flag" || data.description === "cctv" || data.description === "CCTV"
          || data.description === "Camera"|| data.description === "camera") {
        url = "http://www.adseen.tv/casino-royal-stamp145155/";
      } */
      // if (data.description === "pen" || data.description === "Pen") {
      //   url = "https://www.vuugle.tv/pelican-pen/";
      // } else if (
      //   data.description === "flowers" ||
      //   data.description === "flower" ||
      //   data.description === "Flowers" ||
      //   data.description === "Flower"
      // ) {
      //   url = "https://www.vuugle.tv/flowers/";
      // } else if (
      //   data.description === "Coffee Machine" ||
      //   data.description === "coffee machine" ||
      //   data.description === "Coffee machine"
      // ) {
      //   url = "https://www.vuugle.tv/offee-machine/";
      // } else if (
      //   data.description === "chairs" ||
      //   data.description === "chair" ||
      //   data.description === "Chairs" ||
      //   data.description === "Chair"
      // ) {
      //   url = "https://www.amazon.com/s?k=office+chair&ref=nb_sb_noss_1";
      // } else if (data.description === "phone" || data.description === "Phone") {
      //   url = "https://www.vuugle.tv/phone/";
      // } else if (
      //   data.description === "Smartphone" ||
      //   data.description === "Mobile phone"
      // ) {
      //   url = "https://www.vuugle.tv/mobile-phone/";
      // } else if (
      //   data.description === "Trainers" ||
      //   data.description === "trainers"
      // ) {
      //   url = "https://www.vuugle.tv/trainers/";
      // } else if (data.description === "Shoes" || data.description === "shoes") {
      //   url = "https://www.vuugle.tv/shoes/"; // Product Shoes
      // } else if (
      //   data.description === "glasses" ||
      //   data.description === "Glasses" ||
      //   data.description === "Sunglasses" ||
      //   data.description === "sunglasses"
      // ) {
      //   url = "https://www.vuugle.tv/glasses/";
      // } else if (
      //   data.description === "Baseball Cap" ||
      //   data.description === "baseball cap" ||
      //   data.description === "Baseball cap"
      // ) {
      //   url = "https://www.vuugle.tv/baseball-cap/";
      // } else if (data.description === "Watch" || data.description === "watch") {
      //   url = "https://www.vuugle.tv/watch/";
      // } else if (data.description === "AirForce") {
      //   url = "http://www.adseen.tv/nike-air-max/";
      // } else if (data.description === "danny_nike") {
      //   url = "http://www.adseen.tv/nike-air-max/";
      // } else if (
      //   data.description === "bond_daniel" ||
      //   data.description === "bond_old_lady" ||
      //   data.description === "bond_beach" ||
      //   data.description === "bond_car"
      // ) {
      //   url = "http://www.adseen.tv/casino-royal-stamp145155/";
      // } else if (
      //   data.description === "JamesBond" ||
      //   data.description === "Starwars"
      // ) {
      //   //|| data.description === "Starwars"
      //   url = "https://www.vuugle.tv/casino-royal-stamp145155/";
      // } else if (data.description === "6_underground") {
      //   url = "https://www.vuugle.tv/6underground-stamp11234/";
      // } else if (data.description === "Minority_report") {
      //   url = "https://www.vuugle.tv/minorityreport-stamp235662/";
      // } else if (data.description === "Skyfall") {
      //   url = "https://www.vuugle.tv/skyfall-stamp92347/";
      // }
      // if (data.description === "Football" || data.description === "football") {
      //   url = "https://sports.williamhill.com/betting/en-gb/in-play/football";
      // } else
      if (data.description === "Horse racing") {
        url =
          "https://sports.williamhill.com/betting/en-gb/horse-racing/meetings/today/OB_TY261";
      } else if (
        data.description === "Tennis" ||
        data.description === "tennis"
      ) {
        url = "https://sportsbook.draftkings.com/sports/tennis";
      } else if (data.description === "Golf" || data.description === "golf") {
        url = "https://sportsbook.draftkings.com/sports/golf";
      } else if (
        data.description === "Basketball" ||
        data.description === "basketball"
      ) {
        url = "https://sportsbook.draftkings.com/sports/basketball";
      } else if (
        data.description === "Motorsport" ||
        data.description === "motorsport"
      ) {
        url = "https://sports.williamhill.com/betting/en-gb/motor-racing";
      } else if (
        data.description === "Boxing" ||
        data.description === "boxing"
      ) {
        url = "https://sportsbook.draftkings.com/sports/boxing";
      } else if (
        data.description === "Snooker" ||
        data.description === "snooker" ||
        data.description === "Billiards" ||
        data.description === "billiards"
      ) {
        url = "https://sportsbook.draftkings.com/sports/snooker";
      } else if (
        data.description === "Cricket" ||
        data.description === "cricket"
      ) {
        url = "https://sportsbook.draftkings.com/sports/cricket";
      } else if (
        data.description === "Cycling" ||
        data.description === "cycling"
      ) {
        url =
          "https://sports.williamhill.com/betting/en-gb/cycling/OB_EV18167629/mont-ventoux-challenge-2020-winner";
      } else if (
        data.description.toLowerCase() === "american football" ||
        data.description.toLowerCase() === "rugby ball" ||
        data.description.toLowerCase() === "football helmet"
      ) {
        url = "https://sportsbook.draftkings.com/sports/football";
      } else if (data.description.toLowerCase() === "baseball") {
        url = "https://sportsbook.draftkings.com/sports/baseball";
      } else if (data.description.toLowerCase() === "soccer") {
        url = "https://sportsbook.draftkings.com/sports/soccer";
      }

      if (url != null) {
        return (
          <div>
            <div>
              <Sound
                url={VuugleSound}
                playStatus={Sound.status.PLAYING}
                autoLoad="true"
                onFinishedPlaying={setTimeout(() => {
                  window.location = url;
                  //console.log("URL Detection", url);
                }, 3000)}
              />
              <img src={GreenTick} alt="" />
            </div>
            <div>Detected product taking you to vendor</div>
          </div>
        );
      }
      // return <li>{data.description}</li>;
    });
  } else {
    console.log("No Labels");
    setTimeout(() => {
      //console.log("No Detection");
      window.location = "/sorry";
    }, 10000);
  }

  return <div>{labelLists}</div>;
};

export default LabelsDisplay;
