import Axios from "axios";

const Instance = Axios.create({
  baseURL:
"https://app.adseen.tv:8081/api/",
 headers: {
    post: {        // can be common or any other method
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept' : 'application/json'
    }
  }
});

export default Instance;
